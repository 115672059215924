import "./contactform.css";
import { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import Socials from "./Socials";
import { Link } from "react-router-dom";

export default function ContactForm() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    var email = document.getElementById("email-input").value;
    console.log(email);

    fetch('https://emailvalidation.abstractapi.com/v1/?api_key=d8976f87ae8f40359f280db733de0f6a&email=' + email)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if ((data.deliverability === "DELIVERABLE") || ((data.deliverability === "UNKNOWN"  && data.is_valid_format.value) && data.is_mx_found.value)) {
          console.log(data.deliverability);
          emailjs.sendForm('service_b4v7izr', 'template_gryu8cj', form.current, 'Z2U5oiq1nzuZy0y1-')
            .then((result) => {
              console.log(result.text);
            }, (error) => {
              console.log("error" + error.text);
            });
          e.target.reset()
        }else{
          var labelemail = document.getElementById("email-error");
          labelemail.textContent = "😅 Oops... " +email+ " n'est pas un email valide.";
          labelemail.style.display = 'block';
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const resetError= () => {
    var labelemail = document.getElementById("email-error");
    labelemail.style.display = 'none'
  }



  return (
    <div className="contactform">
      <h1>Contactez-nous</h1>
      <p>Agence de communication à Pacé (Rennes)
        <br/>
        02 23 25 08 65 - communication@imagic.fr
      </p>
      <Socials />
      <a class="news" href="/newsletter/">Inscription à la newsletter <svg xmlns="http://www.w3.org/2000/svg" width="12.312" height="12" viewBox="0 0 12.312 12"><path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.235,3.451l.61-.61a.657.657,0,0,1,.932,0L12.118,8.18a.657.657,0,0,1,0,.932L6.776,14.454a.657.657,0,0,1-.932,0l-.61-.61a.66.66,0,0,1,.011-.943L8.557,9.746H.659A.658.658,0,0,1,0,9.087V8.208a.658.658,0,0,1,.659-.659h7.9L5.246,4.394A.655.655,0,0,1,5.235,3.451Z" transform="translate(0 -2.647)" fill="#c9d22c"></path></svg></a>
      <form ref={form} onSubmit={sendEmail}>
        <div className="input-ct">
          <input type="text" name="user_name" placeholder="&nbsp;" required></input>
          <label>Nom Prénom</label>
        </div>
        <div className="input-ct">
          <input type="email" id="email-input" name="user_email" placeholder="&nbsp;" required onChange={resetError}></input>
          <label id="email-label">E-mail</label>
        </div>
        <p style={{color: '#e86245', display: 'none'}} id="email-error"></p>
        <div className="input-ct">
          <input type="text" name="subject" placeholder="&nbsp;" required></input>
          <label>Objet</label>
        </div>
        <div className="input-ct">
          <textarea name="message" placeholder="&nbsp;" cols="30" rows="1" required></textarea>
          <label>Message</label>
        </div>
        <div className="check-ct">
          <input type="checkbox" required /><label>J'ai lu et j'accepte la <Link to="../politique-de-confidentialite" target="_blank" rel="noopener noreferrer">Politique de Confidentialité</Link></label>
        </div>
        <button type="submit">Envoyer</button>
      </form>
    </div>
  );
}
